var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "部门成员列表",
        "close-on-click-modal": false,
        visible: _vm.isShow,
        width: "650px",
        "append-to-body": "",
        "show-close": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.isShow = $event
        },
      },
    },
    [
      _c("div", { staticClass: "fx_member_select" }, [
        _c(
          "ul",
          { staticClass: "select-list" },
          _vm._l(_vm.selectAllAudit1Arr, function (item, index) {
            return _c(
              "li",
              { key: index, staticClass: "select-item" },
              [
                item.type == "member"
                  ? [
                      _c("svg-icon", {
                        staticStyle: {
                          width: "20px",
                          height: "20px",
                          "vertical-align": "-4px",
                          "margin-right": "5px",
                        },
                        attrs: { "icon-class": "memberIcon" },
                      }),
                    ]
                  : _vm._e(),
                item.type == "dept"
                  ? [
                      _c("svg-icon", {
                        staticStyle: {
                          width: "20px",
                          height: "20px",
                          "vertical-align": "-4px",
                          "margin-right": "5px",
                        },
                        attrs: { "icon-class": "deptIcon" },
                      }),
                    ]
                  : _vm._e(),
                item.type == "role"
                  ? [
                      _c("svg-icon", {
                        staticStyle: {
                          width: "20px",
                          height: "20px",
                          "vertical-align": "-4px",
                          "margin-right": "5px",
                        },
                        attrs: { "icon-class": "roleIcon" },
                      }),
                    ]
                  : _vm._e(),
                _c("span", [_vm._v(_vm._s(item.name))]),
                _c(
                  "span",
                  {
                    staticClass: "remove-btn",
                    on: {
                      click: function ($event) {
                        return _vm.onAudit1RemoveSelect(item, index)
                      },
                    },
                  },
                  [
                    _c("svg-icon", {
                      staticStyle: {
                        width: "12px",
                        height: "12px",
                        "vertical-align": "-1px",
                      },
                      attrs: { "icon-class": "del" },
                    }),
                  ],
                  1
                ),
              ],
              2
            )
          }),
          0
        ),
        _c("div", { staticClass: "select-menu" }, [
          _vm.isShowType(1)
            ? _c(
                "div",
                {
                  staticClass: "select-btn",
                  class: { select: _vm.audit1Cur === 1 },
                  on: {
                    click: function ($event) {
                      return _vm.onAudit1SelectTabClick(1)
                    },
                  },
                },
                [_vm._v("成员")]
              )
            : _vm._e(),
          _vm.isShowType(2)
            ? _c(
                "div",
                {
                  staticClass: "select-btn",
                  class: { select: _vm.audit1Cur === 2 },
                  on: {
                    click: function ($event) {
                      return _vm.onAudit1SelectTabClick(2)
                    },
                  },
                },
                [_vm._v("部门")]
              )
            : _vm._e(),
          _vm.isShowType(3)
            ? _c(
                "div",
                {
                  staticClass: "select-btn",
                  class: { select: _vm.audit1Cur === 3 },
                  on: {
                    click: function ($event) {
                      return _vm.onAudit1SelectTabClick(3)
                    },
                  },
                },
                [_vm._v("角色")]
              )
            : _vm._e(),
          _vm.audit1Cur === 1
            ? _c(
                "div",
                {
                  staticClass: "select-search-pane",
                  style: { width: _vm.searchFocus ? "100%" : "114px" },
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.memberParams.searchTxt,
                        expression: "memberParams.searchTxt",
                      },
                    ],
                    attrs: { type: "text", placeholder: "搜索" },
                    domProps: { value: _vm.memberParams.searchTxt },
                    on: {
                      change: _vm.onViewChangeSearchTxt,
                      focus: _vm.onViewFocusSearchTxt,
                      blur: _vm.onViewBlurSearchTxt,
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.memberParams,
                          "searchTxt",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm.memberParams.searchTxt != ""
                    ? _c("svg-icon", {
                        staticStyle: {
                          width: "12px",
                          height: "12px",
                          position: "absolute",
                          right: "20px",
                          top: "12px",
                          cursor: "pointer",
                        },
                        attrs: { "icon-class": "del" },
                        on: { click: _vm.onViewClearSearchTxt },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]),
        _c("div", { staticClass: "select-pane" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.audit1Cur === 1,
                  expression: "audit1Cur===1",
                },
              ],
              staticClass: "member-pane",
            },
            [
              _c("div", { staticClass: "select-department fl" }, [
                _c(
                  "div",
                  {
                    staticClass: "depart-root",
                    on: { click: _vm.onViewMemberClick },
                  },
                  [_vm._v("全部成员")]
                ),
                _c(
                  "div",
                  { staticClass: "x-department-tree" },
                  [
                    _c("el-tree", {
                      attrs: {
                        data: _vm.audit1DeptOptions,
                        props: _vm.deptTreeProps,
                      },
                      on: { "node-click": _vm.onHandleNodeClick },
                    }),
                  ],
                  1
                ),
              ]),
              _vm.memberList && _vm.memberList.length > 0
                ? _c("div", { staticClass: "member-wrapper fr" }, [
                    _c("div", { staticClass: "select-all-item" }, [
                      _c("div", { staticClass: "count-label" }, [
                        _vm._v("已选 "),
                        _c("span", { staticClass: "check-count" }, [
                          _vm._v(_vm._s(_vm.selectMemberLen)),
                        ]),
                        _vm._v("/" + _vm._s(_vm.memberList.length)),
                      ]),
                      _c("div", { staticClass: "check-all x-check" }, [
                        _c("i", { staticClass: "icon-blank" }),
                      ]),
                    ]),
                    _c("div", { staticClass: "select-member" }, [
                      _c(
                        "ul",
                        [
                          _c("li", { staticStyle: { background: "#ebf8f7" } }, [
                            _c("span", { staticClass: "user-name fl" }, [
                              _vm._v("姓名"),
                            ]),
                            _c("span", { staticClass: "user-name fl" }, [
                              _vm._v("编号"),
                            ]),
                            _c("span", { staticClass: "user-name fl" }, [
                              _vm._v("部门"),
                            ]),
                            _c("div", { staticClass: "select-check fr" }),
                          ]),
                          _vm._l(_vm.memberList, function (item, index) {
                            return _c(
                              "li",
                              {
                                key: index,
                                on: {
                                  click: function ($event) {
                                    return _vm.onAudit1CheckBoxUser(index)
                                  },
                                },
                              },
                              [
                                _c("span", { staticClass: "user-name fl" }, [
                                  _vm._v(_vm._s(item.name)),
                                ]),
                                _c("span", { staticClass: "user-name fl" }, [
                                  _vm._v(_vm._s(item.number)),
                                ]),
                                _c("span", { staticClass: "user-name fl" }, [
                                  _vm._v(
                                    _vm._s(
                                      (item.agencyUserDeptNames || []).join(",")
                                    )
                                  ),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "select-check fr",
                                    class: { select: item.checked },
                                  },
                                  [_c("i", { staticClass: "icon-blank" })]
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                  ])
                : _c("div", { staticClass: "member-wrapper fr" }, [
                    _c("span", { staticClass: "empty-tips" }, [
                      _vm._v("没有可选成员"),
                    ]),
                  ]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.audit1Cur === 2,
                  expression: "audit1Cur===2",
                },
              ],
              staticClass: "member-pane",
            },
            [
              _c(
                "div",
                { staticClass: "tree-wrapper" },
                [
                  _c("el-tree", {
                    ref: "audit1DeptTree",
                    attrs: {
                      data: _vm.audit1DeptOptions,
                      props: _vm.deptTreeProps,
                      "show-checkbox": "",
                      "check-strictly": true,
                      "node-key": "agencyDeptId",
                    },
                    on: { "check-change": _vm.onAudit1CheckChange },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.audit1Cur === 3,
                  expression: "audit1Cur===3",
                },
              ],
              staticClass: "member-pane",
            },
            [
              _c(
                "div",
                { staticClass: "tree-wrapper" },
                [
                  _c("el-tree", {
                    ref: "audit1RoleTree",
                    attrs: {
                      data: _vm.audit1RoleOptions,
                      props: _vm.roleTreeProps,
                      "show-checkbox": "",
                      "check-strictly": true,
                      "node-key": "agencyRoleId",
                    },
                    on: { "check-change": _vm.onAudit1RoleCheckChange },
                  }),
                ],
                1
              ),
            ]
          ),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "-20px" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.onAudit1Cancel } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onAuditConfirm } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "fx_member_close", on: { click: _vm.onAuditClose } },
        [
          _c("svg-icon", {
            staticStyle: {
              width: "16px",
              height: "16px",
              "vertical-align": "middle",
            },
            attrs: { "icon-class": "del" },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }