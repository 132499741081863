<template>
  <el-dialog title="部门成员列表" :close-on-click-modal="false" :visible.sync="isShow" width="650px" append-to-body :show-close="false">
    <div class="fx_member_select">
      <ul class="select-list">
        <li v-for="(item, index) in selectAllAudit1Arr" :key="index" class="select-item">
          <template v-if="item.type == 'member'">
            <svg-icon icon-class="memberIcon" style="width: 20px; height: 20px; vertical-align: -4px; margin-right: 5px;" />
          </template>
          <template v-if="item.type == 'dept'">
            <svg-icon icon-class="deptIcon" style="width: 20px; height: 20px; vertical-align: -4px; margin-right: 5px;" />
          </template>
          <template v-if="item.type == 'role'">
            <svg-icon icon-class="roleIcon" style="width: 20px; height: 20px; vertical-align: -4px; margin-right: 5px;" />
          </template>
          <span>{{item.name}}</span>
          <span class="remove-btn" @click="onAudit1RemoveSelect(item, index)">
            <svg-icon icon-class="del" style="width: 12px; height: 12px; vertical-align: -1px;" />
          </span>
        </li>
      </ul>
      <div class="select-menu">
        <div class="select-btn" @click="onAudit1SelectTabClick(1)" v-if="isShowType(1)" :class="{select:audit1Cur===1}">成员</div>
        <div class="select-btn" @click="onAudit1SelectTabClick(2)" v-if="isShowType(2)" :class="{select:audit1Cur===2}">部门</div>
        <div class="select-btn" @click="onAudit1SelectTabClick(3)" v-if="isShowType(3)" :class="{select:audit1Cur===3}">角色</div>
        <div class="select-search-pane" v-if="audit1Cur===1" :style="{'width':(searchFocus ? '100%' : '114px')}">
          <input type="text" v-model="memberParams.searchTxt" placeholder="搜索" @change="onViewChangeSearchTxt" @focus="onViewFocusSearchTxt" @blur="onViewBlurSearchTxt"></input>
          <svg-icon icon-class="del" style="width: 12px; height: 12px; position: absolute; right: 20px; top: 12px; cursor: pointer" @click="onViewClearSearchTxt" v-if="memberParams.searchTxt != ''" />
        </div>
      </div>
      <div class="select-pane">
        <div class="member-pane" v-show="audit1Cur===1">
          <div class="select-department fl">
            <div class="depart-root" @click="onViewMemberClick">全部成员</div>
            <div class="x-department-tree">
              <el-tree :data="audit1DeptOptions" :props="deptTreeProps" @node-click="onHandleNodeClick"></el-tree>
            </div>
          </div>
          <div class="member-wrapper fr" v-if="memberList && memberList.length > 0">
            <div class="select-all-item">
              <div class="count-label">已选 <span class="check-count">{{selectMemberLen}}</span>/{{memberList.length}}</div>
              <div class="check-all x-check">
                <i class="icon-blank"></i>
              </div>
            </div>
            <div class="select-member">
              <ul>
                <li style="background: #ebf8f7">
                  <span class="user-name fl">姓名</span>
                  <span class="user-name fl">编号</span>
                  <span class="user-name fl">部门</span>
                  <div class="select-check fr">
                  </div>
                </li>
                <li v-for="(item,index) in memberList" :key="index" @click="onAudit1CheckBoxUser(index)">
                  <span class="user-name fl">{{item.name}}</span>
                  <span class="user-name fl">{{item.number}}</span>
                  <span class="user-name fl">{{(item.agencyUserDeptNames||[]).join(",")}}</span>
                  <div class="select-check fr" :class="{select:item.checked}">
                    <i class="icon-blank"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="member-wrapper fr" v-else>
            <span class="empty-tips">没有可选成员</span>
          </div>
        </div>

        <div class="member-pane" v-show="audit1Cur===2">
          <div class="tree-wrapper">
            <el-tree :data="audit1DeptOptions" :props="deptTreeProps" show-checkbox :check-strictly="true" @check-change="onAudit1CheckChange" ref="audit1DeptTree" node-key="agencyDeptId"></el-tree>
          </div>
        </div>
        <div class="member-pane" v-show="audit1Cur===3">
          <div class="tree-wrapper">
            <el-tree :data="audit1RoleOptions" :props="roleTreeProps" show-checkbox :check-strictly="true" @check-change="onAudit1RoleCheckChange" ref="audit1RoleTree" node-key="agencyRoleId"></el-tree>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer" style="margin-top: -20px;">
      <el-button @click="onAudit1Cancel">取 消</el-button>
      <el-button type="primary" @click="onAuditConfirm">确 定</el-button>
    </div>
    <div class="fx_member_close" @click="onAuditClose">
      <svg-icon icon-class="del" style="width: 16px; height: 16px; vertical-align:middle;" />
    </div>
  </el-dialog>
</template>
<script>
function fn (option) {
  var json = {}
  var arr = []
  option.forEach((item) => {
    if (!json[item.name]) {
      json[item.name] = true
      arr.push(item)
    }
  })
  return arr
}
import { agencyDeptList, agencyRoleList, agencyUserMemberList, agencySubAdminList } from '@/api/system/agency'
export default {
  props: {
    // isShow: { type: Boolean, default: true }
    agencyId: String|Number,
    // 显示类型数组，默认值,[1,2,3]
    showType: { type: Array, default: () => [1, 2, 3] },
    listUserType: {
      default: "1", //1 全部用户,2 管理员
      type: String
    }
  },
  watch: {
    agencyId: function (val) {
      if( this.listUserType =="1"){
        this.getListMember()
      }else{
        this.getListAdmin()
      }
    }
  },
  data () {
    return {
      isShow: false,
      searchFocus: false,
      audit1Cur: 1,
      memberList: [],
      selectAllAudit1Arr: [],
      audit1DeptOptions: [],
      memberParams: {
        searchTxt: ''
      },
      deptTreeProps: {
        children: 'children',
        label: 'deptName'
      },
      roleTreeProps: {
        children: 'children',
        label: 'roleName'
      },
      selectedHandler: null,
      audit1RoleOptions: []
    }
  },
  mounted () {
    this.memberParams.searchTxt = ''
    this.memberParams.agencyDeptId = undefined
    this.isAudit1Focus = false

    // this.formAuditAuth.auditAuthLevel = 1
  },
  computed: {
    selectMemberLen () {
      const member = this.memberList.filter((item) => item.checked)
      return fn(member).length
    }
  },
  methods: {
    show (selectedMember, callback) {
      this.selectAllAudit1Arr = selectedMember || [];

      // 如果listUserType 是1
      if (this.listUserType == "1") {
        this.getListMember()
      } else {
        this.getListAdmin()
      }

      // 如果 showType 包含2
      if (this.showType.includes(2)) {
        this.getListDept()
      }

      if (this.showType.includes(3)) {
        this.getListRole()
      }

      this.selectedHandler = callback;
      this.isShow = true;
    },
    onAudit1SelectTabClick (index) {
      this.audit1Cur = index
    },
    onAuditConfirm () {
      this.selectedHandler(JSON.parse(JSON.stringify(this.selectAllAudit1Arr)));
      this.selectAllAudit1Arr = [];
      this.audit1Cur = 1;
      this.isShow = false;
      this.clearTree();
    },
    onAudit1Cancel () {
      this.isShow = false;
      this.clearTree();
    },
    onAuditClose () {
      this.isShow = false;
      this.clearTree();
    },
    // 请求组织架构列表
    getListDept () {
      const _this = this
      agencyDeptList().then((response) => {
        const treeList = response.data
        _this.audit1DeptOptions = _this.handleTree(treeList, 'agencyDeptId')

        if (!_this.isBlank(_this.selectAllAudit1Arr)) {
          const auditArr = []
          for (let j = 0; j < _this.selectAllAudit1Arr.length; j++) {
            for (let k = 0; k < treeList.length; k++) {
              if (_this.selectAllAudit1Arr[j].agencyDeptId == treeList[k].agencyDeptId) {
                auditArr.push(_this.selectAllAudit1Arr[j].agencyDeptId)
                _this.$refs.audit1DeptTree.setCheckedKeys(auditArr)
              }
            }
          }
        }
      })
    },
    // 请求成员列表
    getListMember () {
      const _this = this
      // 如果是限制只读取管理员listUserType 是2
      if (this.listUserType != "1") {
        // this.getListAdmin()
        // return;
      }

      const agencyId = !_this.isBlank(_this.memberParams.agencyId) ? _this.memberParams.agencyId : _this.agencyId
      if(agencyId){
      agencyUserMemberList(agencyId, _this.memberParams.searchTxt, _this.memberParams.agencyDeptId).then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].checked = false
        }
        _this.memberList = response.data

        if (!_this.isBlank(_this.selectAllAudit1Arr)) {
          for (let j = 0; j < _this.selectAllAudit1Arr.length; j++) {
            for (let k = 0; k < _this.memberList.length; k++) {
              if (_this.selectAllAudit1Arr[j].agencyUserId == _this.memberList[k].agencyUserId) {
                _this.memberList[k].checked = true
              }
            }
          }
        }
      })
      }
    },
    // 请求管理员列表
    getListAdmin () {
      const _this = this
      const agencyId = !_this.isBlank(_this.memberParams.agencyId) ? _this.memberParams.agencyId : _this.agencyId
      agencySubAdminList({
        searchTxt: this.memberParams.searchTxt,
        pageNum:1,
        pageSize:100
      }).then((response) => {
        for (let i = 0; i < response.rows.length; i++) {
          response.rows[i].checked = false
        }
        _this.memberList = response.rows

        if (!_this.isBlank(_this.selectAllAudit1Arr)) {
          for (let j = 0; j < _this.selectAllAudit1Arr.length; j++) {
            for (let k = 0; k < _this.memberList.length; k++) {
              if (_this.selectAllAudit1Arr[j].agencyUserId == _this.memberList[k].agencyUserId) {
                _this.memberList[k].checked = true
              }
            }
          }
        }
      })
    },


    // 请求角色列表
    getListRole () {
      const _this = this
      agencyRoleList().then((response) => {
        const treeList = response.data
        _this.audit1RoleOptions = _this.handleTree(treeList, 'agencyRoleId')
        if (!_this.isBlank(_this.selectAllAudit1Arr)) {
          const audit1Arr = []
          for (let j = 0; j < _this.selectAllAudit1Arr.length; j++) {
            for (let k = 0; k < treeList.length; k++) {
              if (_this.selectAllAudit1Arr[j].agencyRoleId == treeList[k].agencyRoleId) {
                audit1Arr.push(_this.selectAllAudit1Arr[j].agencyRoleId)
                _this.$refs.audit1RoleTree.setCheckedKeys(audit1Arr)
              }
            }
          }
        }
      })
    },
    onHandleNodeClick (data) {
      this.memberParams.agencyDeptId = data.agencyDeptId
       // 如果listUserType 是1
      if (this.listUserType == "1") {
        this.getListMember()
      } else {
        this.getListAdmin()
      }
    },
    onAudit1CheckChange (data, checked, indeterminate) {
      if (checked) {
        this.selectAllAudit1Arr.push({
          type: 'dept',
          name: data.deptName,
          agencyDeptId: data.agencyDeptId,
          level: data.level
        })
      } else {
        const findIndex = this.selectAllAudit1Arr.findIndex((value) => value.agencyDeptId == data.agencyDeptId)
        if (findIndex != -1) {
          this.selectAllAudit1Arr.splice(findIndex, 1)
        }
      }
    },
    onViewChangeSearchTxt () {
      if (this.listUserType == "1") {
        this.getListMember()
      } else {
        this.getListAdmin()
      }
    },
    onViewFocusSearchTxt () {
      this.searchFocus = true
    },
    onViewBlurSearchTxt () {
      this.searchFocus = false
    },
    onViewClearSearchTxt () {
      this.isAudit1Focus = false
      this.memberParams.searchTxt = ''
      if (this.listUserType == "1") {
        this.getListMember()
      } else {
        this.getListAdmin()
      }
    },
    onViewMemberClick () {
      this.memberParams.agencyDeptId = undefined
      if (this.listUserType == "1") {
        this.getListMember()
      } else {
        this.getListAdmin()
      }
    },
    onAudit1RoleCheckChange (data, checked, indeterminate) {
      if (checked) {
        this.selectAllAudit1Arr.push({
          type: 'role',
          name: data.roleName,
          agencyRoleId: data.agencyRoleId
        })
      } else {
        const findIndex = this.selectAllAudit1Arr.findIndex((value) => value.agencyRoleId == data.agencyRoleId)
        if (findIndex != -1) {
          this.selectAllAudit1Arr.splice(findIndex, 1)
        }
      }
    },
    onAudit1CheckBoxUser (index) {
      this.memberList[index].checked = !this.memberList[index].checked
      if (this.memberList[index].checked) {
        this.selectAllAudit1Arr.push({
          type: 'member',
          name: this.memberList[index].name,
          number: this.memberList[index].number,
          agencyUserId: this.memberList[index].agencyUserId
        })
      } else {
        const findIndex = this.selectAllAudit1Arr.findIndex((value) => value.name == this.memberList[index].name)
        if (findIndex != -1) {
          this.selectAllAudit1Arr.splice(findIndex, 1)
        }
      }
    },
    onAudit1RemoveSelect (item, index) {
      this.selectAllAudit1Arr.splice(index, 1)
      if (item.type == 'member') {
        const findIndex = this.memberList.findIndex((value) => value.name == item.name)
        this.memberList[findIndex].checked = !this.memberList[findIndex].checked
      }
      if (item.type == 'dept') {
        this.$refs.audit1DeptTree.setChecked(item.agencyDeptId, false, false)
      }
      if (item.type == 'role') {
        this.$refs.audit1RoleTree.setChecked(item.agencyRoleId, false, false)
      }
    },
    clearTree () {
      this.selectAllAudit1Arr = [];
      this.memberList = [];
      this.audit1DeptOptions = [];
      this.audit1RoleOptions = [];
      this.audit1Cur = 1;
    },
    // 检查showType中是否有指定值
    isShowType (type) {
      return this.showType.indexOf(type) != -1
    },
  }
}
</script>
<style lang="scss" scoped>

.fx_member_select {
  margin-top: -30px;
  .select-menu {
    position: relative;
    height: 40px;
    padding: 0 10px;
    border: 1px solid #e0e0e0;
    .select-btn {
      text-align: center;
      display: inline-block;
      cursor: pointer;
      line-height: 38px;
      height: 38px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 5px;
      margin: 0 10px;
      &.select {
        color: #3476F0;
        border-bottom: solid 4px #3476F0;
      }
    }
    .select-search-pane {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 114px;
      background: #fff;
      padding: 0 10px;
      box-sizing: border-box;
      transition: width 0.2s;
      input {
        line-height: 26px !important;
        height: 26px !important;
        width: 100%;
        border: 0;
        background: #f4f4f4;
        border-radius: 13px;
        margin-top: 6px;
        padding: 0 10px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        outline: 0;
        &:focus {
          border: none;
          outline: 0;
        }
      }
    }
  }
  .select-pane {
    border: 1px solid #e0e0e0;
    border-top: none;
    height: 320px;
    .member-pane {
      .select-department {
        border-right: solid 1px #e0e0e0;
        width: 35%;
        box-sizing: border-box;
        height: 320px;
        .depart-root {
          background: #ebf8f7;
          text-indent: 10px;
          line-height: 30px;
          cursor: pointer;
        }
        .x-department-tree {
          height: 290px;
          overflow-y: auto;
        }
      }
      .member-wrapper {
        width: 65%;
        .empty-tips {
          display: inline-block;
          margin: 10px;
          color: #989898;
        }
        .select-all-item {
          cursor: pointer;
          height: 30px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-box-pack: space-between;
          -ms-flex-pack: space-between;
          -webkit-justify-content: space-between;
          justify-content: space-between;
          -webkit-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
          padding: 0 8px 0 12px;
          border-bottom: solid 1px #e9e9e9;
          .count-label {
            color: #3476F0;
          }
        }
        .select-member {
          height: 290px;
          overflow-y: auto;
          ul {
            margin: 0;
            padding: 0;
            li {
              cursor: pointer;
              padding: 0 10px 0 10px;
              position: relative;
              line-height: 30px;
              width: 100%;
              box-sizing: border-box;
              display:flex;
              justify-content: space-between;
              align-items: center;
              &:hover {
                background: #f5f7fa;
              }
              .user-name {
                width: 28%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              .select-check {
                cursor: pointer;
                width: 16px;
                .icon-blank {
                  display: inline-block;
                  width: 14px;
                  height: 14px;
                  border: 1px solid #91a1b7;
                  position: relative;
                  background: #fff;
                  -webkit-transition: all 218ms ease-in-out;
                  -moz-transition: all 218ms ease-in-out;
                  -o-transition: all 218ms ease-in-out;
                  transition: all 218ms ease-in-out;
                  &::before {
                    content: '';
                    display: block;
                    opacity: 0;
                    -webkit-transition: opacity 218ms ease-in-out;
                    -moz-transition: opacity 218ms ease-in-out;
                    -o-transition: opacity 218ms ease-in-out;
                    transition: opacity 218ms ease-in-out;
                    height: 6px;
                    width: 10px;
                    border-left: solid 2px #fff;
                    border-bottom: solid 2px #fff;
                    position: absolute;
                    top: 2px;
                    left: 1px;
                    -webkit-transform: rotate(-45deg);
                    -moz-transform: rotate(-45deg);
                    -ms-transform: rotate(-45deg);
                    -o-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                  }
                }
                &.select {
                  .icon-blank {
                    border-color: #3476F0;
                    background: #3476F0;
                    &::before {
                      opacity: 1;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .tree-wrapper {
        width: 100%;
        height: 320px;
        box-sizing: border-box;
        overflow-y: auto;
        padding-top: 10px;
      }
    }
  }
}
.fx_member_close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.select-list {
  width: 100%;
  height: 80px;
  border: 1px solid #e0e0e0;
  margin-bottom: 10px;
  overflow: auto;
  padding: 0;
  .select-item {
    display: inline-block;
    line-height: 30px;
    margin: 5px 0 0 5px;
    padding: 0 10px;
    border-radius: 1px;
    background: #f3f6fc;
    list-style: none;
    cursor: pointer;
    .remove-btn {
      margin-left: 10px;
      padding: 3px;
    }
  }
}
</style>
